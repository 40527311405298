.rt {
  position: relative;
  z-index: 1;
  overflow: hidden;
  font-family: sans-serif;
  color: #4c4c4c; }
  .rt * {
    box-sizing: border-box; }

.rt-visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.rt-controls {
  display: inline-block;
  padding: 8px;
  margin: 0 0 10px 0;
  background-color: #fff; }

.rt-controls__button {
  display: inline-block;
  width: 44px;
  height: 44px;
  overflow: hidden;
  background-color: #fff;
  color: transparent;
  white-space: nowrap;
  padding: 10px;
  outline: none; }
  .rt-controls__button:last-child {
    margin-right: 0; }
  .rt-controls__button:hover {
    background-color: #f0f0f0; }
  .rt-controls__button:focus, .rt-controls__button:active {
    background-color: #f0f0f0; }

.rt-controls__button[disabled] {
  opacity: 0.5; }

@media (min-width: 1000px) {
  .rt-controls__button--toggle {
    display: none; } }

.rt-element {
  position: relative;
  height: 40px;
  line-height: 40px;
  background: #06f;
  color: #fff;
  text-align: center; }

.rt-element__content {
  padding: 0 10px;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  text-overflow: ellipsis; }

.rt-element__tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  z-index: 2;
  padding: 10px;
  line-height: 1.3;
  white-space: nowrap;
  text-align: left;
  background: #4c4c4c;
  color: white;
  transform: translateX(-50%) scale(0);
  pointer-events: none; }
  .rt-element__tooltip::before {
    position: absolute;
    top: 100%;
    left: 50%;
    border-top: 6px solid #4c4c4c;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    transform: translateX(-50%);
    content: ' '; }

.rt-element:hover > .rt-element__tooltip,
.rt-element:focus > .rt-element__tooltip {
  transform: translateX(-50%) scale(1);
  transition: transform 0s 0.3s; }

.rt-grid,
.rt-grid__cell {
  position: absolute;
  top: 0;
  bottom: 0; }

.rt-grid {
  left: 0;
  right: 0; }

.rt-grid__cell {
  background: #fff;
  border-left: 1px solid #eee; }

.rt-layout {
  margin-left: -200px; }
  @media (min-width: 1000px) {
    .rt-layout {
      margin-left: 0; } }
  .rt-layout.rt-is-open {
    margin-left: 0; }

.rt-layout__side {
  position: relative;
  z-index: 2;
  display: inline-block;
  width: 240px;
  vertical-align: top; }

.rt-layout__main {
  display: inline-block;
  width: calc(100% - 240px);
  vertical-align: top; }

.rt-layout__timeline {
  overflow-x: auto; }

.rt-marker {
  position: absolute;
  z-index: 2;
  top: 40px;
  bottom: 0;
  margin-left: -1px;
  border-left: 2px solid;
  opacity: 0;
  pointer-events: none; }

.rt-marker.rt-is-visible {
  opacity: 1; }

.rt-marker--now {
  color: #ff007f;
  border-color: rgba(255, 0, 127, 0.5); }

.rt-marker--pointer {
  color: #888;
  border-color: rgba(136, 136, 136, 0.5); }

.rt-marker--pointer.rt-is-highlighted {
  color: #484848;
  border-color: #858585; }

.rt-marker__label {
  position: absolute;
  bottom: 100%;
  left: 50%;
  display: table;
  min-width: 70px;
  height: 40px;
  padding: 0 10px;
  line-height: 1.1;
  text-align: center;
  background: currentColor;
  transform: translateX(-50%);
  font-size: 16px;
  font-weight: bold; }
  .rt-marker__label::before {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -6px;
    transform: translateX(-1px);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid currentColor;
    content: ' '; }

.rt-marker__content {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  color: white; }

.rt-sidebar {
  background-color: #fff;
  box-shadow: 10px 0 10px -5px rgba(12, 12, 12, 0.1); }

.rt-sidebar__header {
  background-color: #fff; }

.rt-sidebar__header.rt-is-sticky {
  position: fixed;
  top: 0;
  z-index: 2;
  direction: rtl;
  margin-left: 200px; }
  @media (min-width: 1000px) {
    .rt-sidebar__header.rt-is-sticky {
      margin-left: 0;
      direction: ltr; } }

.rt-layout.rt-is-open .rt-sidebar__header.rt-is-sticky {
  margin-left: 0;
  direction: ltr; }

.rt-timebar {
  background-color: #eee; }

.rt-timebar__row {
  position: relative;
  height: 41px;
  overflow: hidden;
  line-height: 40px;
  border-bottom: 1px solid #eee; }
  .rt-timebar__row:last-child {
    border-bottom-color: #d5d5d5; }

.rt-timebar__cell {
  position: absolute;
  text-align: center;
  background-color: #fff;
  border-left: 1px solid #eee; }

.rt-timebar-key {
  height: 41px;
  padding-right: 20px;
  line-height: 40px;
  text-align: right;
  font-weight: bold;
  border-bottom: 1px solid #eee; }
  .rt-timebar-key:last-child {
    border-bottom-color: #d5d5d5; }

.rt-timeline {
  position: relative;
  overflow: hidden; }

.rt-timeline__header-scroll {
  overflow-x: auto; }
  .rt-timeline__header-scroll::-webkit-scrollbar {
    display: none; }

.rt-timeline__header.rt-is-sticky {
  position: fixed;
  top: 0;
  z-index: 1;
  overflow: hidden; }

.rt-timeline__body {
  position: relative;
  background: white; }

.rt-track__elements {
  position: relative;
  height: 61px;
  border-bottom: 1px solid #eee; }

.rt-track__element {
  position: absolute;
  height: 40px;
  top: 10px; }

.rt-track-key__entry {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 61px;
  line-height: 60px;
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid #eee; }

.rt-track-keys > .rt-track-key >
.rt-track-key__entry {
  padding-left: 20px; }

.rt-track-keys > .rt-track-key >
.rt-track-keys > .rt-track-key >
.rt-track-key__entry {
  padding-left: 40px; }

.rt-track-keys > .rt-track-key >
.rt-track-keys > .rt-track-key >
.rt-track-keys > .rt-track-key >
.rt-track-key__entry {
  padding-left: 60px; }

.rt-track-keys > .rt-track-key >
.rt-track-keys > .rt-track-key >
.rt-track-keys > .rt-track-key >
.rt-track-keys > .rt-track-key >
.rt-track-key__entry {
  padding-left: 80px; }

.rt-track-keys > .rt-track-key >
.rt-track-keys > .rt-track-key >
.rt-track-keys > .rt-track-key >
.rt-track-keys > .rt-track-key >
.rt-track-keys > .rt-track-key >
.rt-track-key__entry {
  padding-left: 100px; }

.rt-track-key__toggle {
  overflow: hidden;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background: #4c4c4c no-repeat center/10px;
  color: transparent; }
  .rt-track-key__toggle:hover, .rt-track-key__toggle:focus {
    background-color: #191919; }

.rt-track-key__toggle--close {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iOCIgdmlld0JveD0iMTYgMjQgMjQgOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYgMjRoMjR2OEgxNnoiIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg=="); }

.rt-track-key__toggle--open {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjE2IDE1IDI0IDI1IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PHBhdGggZD0iTTMyIDE2djI0aC04VjE2eiIvPjxwYXRoIGQ9Ik0xNiAyNGgyNHY4SDE2eiIvPjwvZz48L3N2Zz4="); }

.rt-track-key__title {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.rt-track-key__side-button {
  height: 60px;
  width: 60px;
  color: transparent;
  background: transparent; }
  .rt-track-key__side-button:hover, .rt-track-key__side-button:focus {
    background: #eee;
    color: transparent; }
  .rt-track-key__side-button::before {
    position: absolute;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-left: -8px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDQ4Mi4xMzYgNDgyLjEzNSI+PHBhdGggZmlsbD0iIzc2NzY3NiIgZD0iTTQ1NS40ODIgMTk4LjE4NEwzMjYuODMgMzI2LjgzMmMtMzUuNTM2IDM1LjU0LTkzLjExIDM1LjU0LTEyOC42NDcgMGwtNDIuODgtNDIuODg2IDQyLjg4LTQyLjg3NiA0Mi44ODQgNDIuODc2YzExLjg0NSAxMS44MjIgMzEuMDY0IDExLjg0NiA0Mi44ODYgMGwxMjguNjQ0LTEyOC42NDNjMTEuODE2LTExLjgzIDExLjgxNi0zMS4wNjYgMC00Mi45bC00Mi44OC00Mi44OGMtMTEuODIzLTExLjgxNS0zMS4wNjUtMTEuODE1LTQyLjg4OCAwbC00NS45MyA0NS45MzVjLTIxLjI5LTEyLjUzLTQ1LjQ5LTE3LjkwNS02OS40NS0xNi4yOWw3Mi41LTcyLjUyN2MzNS41MzYtMzUuNTIgOTMuMTM3LTM1LjUyIDEyOC42NDUgMGw0Mi44ODYgNDIuODg0YzM1LjUzNiAzNS41MjMgMzUuNTM2IDkzLjE0IDAgMTI4LjY2MnpNMjAxLjIwNiAzNjYuNjk4bC00NS45MDMgNDUuOWMtMTEuODQ1IDExLjg0Ni0zMS4wNjQgMTEuODE3LTQyLjg4IDBsLTQyLjg4NS00Mi44OGMtMTEuODQ1LTExLjgyMi0xMS44NDUtMzEuMDQyIDAtNDIuODg3bDEyOC42NDYtMTI4LjY0NWMxMS44Mi0xMS44MTQgMzEuMDctMTEuODE0IDQyLjg4NCAwbDQyLjg4NiA0Mi44ODYgNDIuODc2LTQyLjg4Ni00Mi44NzYtNDIuODhjLTM1LjU0LTM1LjUyMi05My4xMTMtMzUuNTIyLTEyOC42NSAwbC0xMjguNjUgMTI4LjY0Yy0zNS41MzcgMzUuNTQ2LTM1LjUzNyA5My4xNDcgMCAxMjguNjUzTDY5LjU0IDQ1NS40OGMzNS41MSAzNS41NCA5My4xMSAzNS41NCAxMjguNjQ2IDBsNzIuNDk2LTcyLjVjLTIzLjk1NiAxLjU5OC00OC4wOTItMy43ODMtNjkuNDc0LTE2LjI4MnoiLz48L3N2Zz4=");
    content: ' '; }

.rt-track-keys {
  margin: 0;
  padding-left: 0;
  list-style: none; }
